import { Dropdown } from "primereact/dropdown";
import { Nullable } from "primereact/ts-helpers";
import { Route, Travel } from "../../../../service/type";
import { LightRouteDisplayTemplate } from "../../../route/RouteDisplayTemplate";
import { Calendar, CalendarSelectEvent } from "primereact/calendar";
import React, { ReactNode, useRef, useState } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import { ListBox, ListBoxChangeEvent } from "primereact/listbox";
import { TravelItem } from "../TravelItem";
import { useGetUpcomingTravelsQuery } from "../../../../service/travelMgrApi";
import { isEqual } from "date-fns";
import { parseDateString } from "../../../../DateUtils";

export interface TravelHeaderProps {
    selectedRoute?: Route;
    routes?: Route[];
    isLoading: boolean;
    onRouteChange: (route: Route) => void,
    onTravelSelect: (travel: Travel) => void
}

export const BookingFormFilter = ({ 
    selectedRoute,
    routes,
    isLoading,
    onRouteChange,
    onTravelSelect
}: TravelHeaderProps) => {

    const [selectedDate, setSelectedDate] = useState<Nullable<Date>>(new Date());

    const { 
        data: travelQData, 
        isLoading: travelQLoading, 
        isFetching: travelQIsFetching 
    } = useGetUpcomingTravelsQuery();
    const [selectedTravel, setSelectedTravel] = useState<Travel>();


    const routeItemTemplate = (route: Route) => (
        route ? <LightRouteDisplayTemplate route={route} titleFontSize={1} contentFontSize={2} /> 
             : <div>Choisissez un itinéraire</div>
    );

    
    // List template
    const listTemplate = (travel: Travel): ReactNode => {
        return <TravelItem
            key={travel.id}
            travel={travel}
            primaryBg= {false}
            lightRoute={true}
            isSelected={travel.id === selectedTravel?.id}
            onSelect={setSelectedTravel}
            />
    }


    const getTravelsForDate = (date: Date): Travel[] => {
        if (travelQData?.length) {
            const sortedTravels = [...travelQData]
            .filter(travel => isEqual(parseDateString(travel.startDate), date))
            .sort((a, b) =>(a.startDate + a.startTime).localeCompare(b.startDate + b.startTime));
            return sortedTravels
        }
        return []
    }

    const op = useRef<OverlayPanel>(null);


    return (
        <div className="flex flex-column gap-3">
            <OverlayPanel 
                ref={op}
                pt={{
                content: {
                    className: "p-0"
                }}}>
                <ListBox 
                    value={selectedTravel}
                    onChange={(e: ListBoxChangeEvent) => { onRouteChange((e.value as Travel).route); onTravelSelect(e.value); op.current?.hide()}} 
                    options={getTravelsForDate(selectedDate as Date)}
                    itemTemplate={listTemplate} className="w-full" listStyle={{ maxHeight: '350px' }}
                    pt={{
                        wrapper: {
                            className: "common-scroll"
                        }
                    }}
                />
            </OverlayPanel>

            <Dropdown 
                loading={isLoading}
                value={selectedRoute}
                id='route'
                itemTemplate={routeItemTemplate}
                valueTemplate={routeItemTemplate}
                scrollHeight="400px"
                onChange={(e) => {onRouteChange(e.value)}}
                options={routes}
                optionLabel="name"
                placeholder="Choisissez un itinéraire"
                className="w-full"
            />
            <div>
            <Calendar
                value={selectedDate}
                onSelect={(event: CalendarSelectEvent) => {
                    op.current?.toggle(event.originalEvent)
                    setTimeout(()=> {
                        setSelectedDate(event.value as Date)

                    }, 0)
                }}
                dateFormat="dd M"
                minDate={new Date()}
                inline
                locale='fr'
                pt={{
                    panel: {
                        className: 'w-full'
                    }
                }}
            />
            </div>
        </div>
    );
};
