import { classNames } from "primereact/utils";
import { Divider } from "primereact/divider";
import { LightRouteDisplayTemplate, SimpleRouteDisplayTemplate } from "../../route/RouteDisplayTemplate";
import { formatDateString, formatTimeString } from "../../../DateUtils";
import { Travel } from "../../../service/type";

export interface TravelItemProps {
    travel: Travel
    isSelected: boolean
    lightRoute?: boolean
    primaryBg?: boolean
    onSelect: (travel: Travel) => void;
}

export const TravelItem = ({ travel, isSelected, lightRoute, primaryBg = true, onSelect }: TravelItemProps) => {
    console.log(lightRoute)
    return (
        <div 
            className={classNames({"bg-primary-50": isSelected && primaryBg, "hover:bg-primary-50": primaryBg})} 
            onClick={() => onSelect(travel)}
        >
            <div className="flex cursor-pointer border-round-xl py-3">
                <div className="flex-grow-1 overflow-hidden text-overflow-ellipsis">
                    {
                    lightRoute ? 
                    <LightRouteDisplayTemplate 
                        route={travel.route} 
                        titleFontSize={2} 
                        contentFontSize={1}
                    /> :
                    <SimpleRouteDisplayTemplate 
                        route={travel.route} 
                        titleFontSize={2} 
                        contentFontSize={1}
                    />
                    }
                </div>
                <TravelDateTime travel={travel} />
            </div>
            <Divider pt={{ root: { className: "m-0" } }}/>
        </div>
    );
};



export const TravelDateTime = ({ travel, horizontal}: {travel: Travel, horizontal?: boolean}) => {
    const startDateBody = (travel: Travel) => (
        <div className="white-space-nowrap overflow-hidden text-overflow-ellipsis text-primary text-lg font-bold align-content-center">
            {formatDateString(travel.startDate)}
        </div>
    );

    const startTimeBody = (travel: Travel) => (
        <div className="block text-center align-content-center">
            {formatTimeString(travel.startTime)} - {formatTimeString(travel.endTime)}
        </div>
    );

    return (
        <div className="mx-3"> {
            horizontal ? 
            <div className="flex gap-3 py-2">
                {startDateBody(travel)} <Divider layout="vertical" /> {startTimeBody(travel)}
                </div>
            :
            <div className="flex flex-column justify-content-center">
                {startDateBody(travel)}
                {startTimeBody(travel)}
            </div>
        }
        </div>
    );
};