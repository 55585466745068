// External Dependencies
import { useState } from "react"

// Types and Services
import { Route, Travel } from "../../../../service/type" 
import { 
    useGetAllRouteQuery
} from "../../../../service/travelMgrApi"

import { TravelDateTime } from "../TravelItem"
import { BookingFormFilter } from "./BookingFormFilter"
import { FullRouteDisplayTemplate } from "../../../route/RouteDisplayTemplate"
import { Divider } from "primereact/divider"

// Main Component
export const BookingForm = () => {
    // Queries
    const { 
        data: routesData, 
        isLoading: routeQLoading, 
        isFetching: routeQIsFetching 
    } = useGetAllRouteQuery();

    // State
    const [selectedRoute, setSelectedRoute] = useState<Route>();
    const [selectedTravel, setSelectedTravel] = useState<Travel>();

    return (
        <div className="flex">
            <div className="card zoomin animation-duration-500 mx-2 p-3 h-full">
                <BookingFormFilter
                    selectedRoute={selectedRoute}
                    routes={routesData}
                    onTravelSelect={setSelectedTravel}
                    isLoading={routeQLoading || routeQIsFetching}
                    onRouteChange={setSelectedRoute}
                />
            </div>
            
            {selectedRoute &&  <div className="card zoomin animation-duration-500 mx-2 p-3 h-full common-scroll" style={{overflowX: 'auto'}}>
                <FullRouteDisplayTemplate route={selectedRoute} titleFontSize={2} contentFontSize={1} displayAlertes={false}/>
                {
                selectedTravel && 
                    <div className="mx-3">
                        <TravelDateTime travel={selectedTravel} horizontal={true}/>
                        <Divider pt={{
                            root: {
                                className: "m-0 p-0"
                            }
                        }}/>
                    </div>
                }
            </div>
            } 
        </div>
    )

}