import { Card } from "primereact/card";
import { CardHeader } from "../../component/shared/CardHeader";
import { TabPanel, TabView } from "primereact/tabview";
import { TravelsList } from "./components/TravelsList";
import { BookingForm } from "./components/form/BookingForm";

export const TravelHomePage = () => {
    

    return (
        <Card 
            title={<CardHeader title="Gestion des réservations" buttonLabel="Effectuer une reservation" />} 
            className='shadow-none' 
            style={{backgroundColor: "transparent"}}
        >
            <TabView>
                <TabPanel header="Réservations" leftIcon="pi pi-bars mr-2">
                    <TravelsList />
                </TabPanel>
                <TabPanel header="Nouvelle Réservation" leftIcon="pi pi-plus-circle mr-2">
                    <BookingForm />
                </TabPanel>
            </TabView>
        </Card>
    );
};