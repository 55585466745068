// External Dependencies
import { DataView } from "primereact/dataview"
import { ProgressSpinner } from "primereact/progressspinner"
import { Nullable } from "primereact/ts-helpers"
import { ReactNode, useCallback, useEffect, useState } from "react"
import { addDays, isAfter, isBefore, isEqual } from "date-fns"

// Types and Services
import { Route, Travel } from "../../../service/type" 
import { 
    useGetAllRouteQuery, 
    useGetPastTravelsQuery, 
    useGetUpcomingTravelsQuery 
} from "../../../service/travelMgrApi"

// Utils
import { 
    atEndOfDay, 
    atStartOfDay, 
    parseDateString 
} from "../../../DateUtils"
import { TabPanel, TabView } from "primereact/tabview"
import { TravelHeader } from "./TravelHeader"
import { TravelPassengersList } from "./TravelPassengersList"
import { TravelItem } from "./TravelItem"

// Main Component
export const TravelsList = () => {

    // Queries
    const { 
        data: routesData, 
        isLoading: routeQLoading, 
        isFetching: routeQIsFetching 
    } = useGetAllRouteQuery();

    const { 
        data: travelQData, 
        isLoading: travelQLoading, 
        isFetching: travelQIsFetching 
    } = useGetUpcomingTravelsQuery();

    const { 
        data: pastTravelQData, 
        isLoading: pastTravelQLoading, 
        isFetching: pastTravelQIsFetching 
    } = useGetPastTravelsQuery();

    // State
    const [selectedRoute, setSelectedRoute] = useState<Route>();
    const [selectedTravel, setSelectedTravel] = useState<Travel>();
    const startDate = new Date();
    const [upcomingTravelDateRange, setUpcomingTravelDateRange] = useState<Nullable<(Date | null)[]>>([startDate, addDays(new Date(), 7)]);
    const [pastTravelDateRange, setPastTravelDateRange] = useState<Nullable<(Date | null)[]>>([addDays(new Date(), -7), startDate]);


    // Effects
    useEffect(() => {
        if (travelQData?.length) {
            const sortedTravels = [...travelQData].sort((a, b) => 
                (a.startDate + a.startTime)
                    .localeCompare(b.startDate + b.startTime)
            );
            setSelectedTravel(sortedTravels[0]);
        }
    }, [travelQData]);

    // Callbacks
    const filterTravelData = useCallback(() => {
        if (!travelQData) return [];

        return travelQData
            .filter(travel => 
                !selectedRoute || selectedRoute.id === travel.route.id
            )
            .filter(travel => {
                if (!upcomingTravelDateRange?.[0] || !upcomingTravelDateRange?.[1]) return true;

                const travelDate = parseDateString(travel.startDate);
                const startDateRange = atStartOfDay(upcomingTravelDateRange[0]);
                const endDateRange = atEndOfDay(upcomingTravelDateRange[1]);

                return (isAfter(travelDate, startDateRange) || isEqual(travelDate, startDateRange)) &&
                       (isBefore(travelDate, endDateRange) || isEqual(travelDate, endDateRange));
            })
            .sort((a, b) => (a.startDate+a.startTime).localeCompare(b.startDate+b.startTime));
    }, [travelQData, selectedRoute, upcomingTravelDateRange]);

    // Callbacks
    const filterPastTravelData = useCallback(() => {
        if (!pastTravelQData) return [];

        return pastTravelQData
            .filter(travel => 
                !selectedRoute || selectedRoute.id === travel.route.id
            )
            .filter(travel => {
                if (!pastTravelDateRange?.[0] || !pastTravelDateRange?.[1]) return true;

                const travelDate = parseDateString(travel.startDate);
                const startDateRange = atStartOfDay(pastTravelDateRange[0]);
                const endDateRange = atEndOfDay(pastTravelDateRange[1]);

                return (isAfter(travelDate, startDateRange) || isEqual(travelDate, startDateRange)) &&
                       (isBefore(travelDate, endDateRange) || isEqual(travelDate, endDateRange));
            })
            .sort((a, b) => -(a.startDate+a.startTime).localeCompare(b.startDate+b.startTime));
    }, [pastTravelQData, selectedRoute, pastTravelDateRange]);

    // Loading spinner
    const loader = (
        <div className="text-center align-content-center" style={{backgroundColor: "transparent", height: "80vh"}}>
            <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" animationDuration=".5s" />
        </div>
    );

    // List template
    const listTemplate = (travels: Travel[]): ReactNode[] => {
        if (!travels?.length) return [<div key="empty"></div>];

        return travels.map((travel, index) => (
            <TravelItem
                key={travel.id}
                travel={travel}
                isSelected={travel.id === selectedTravel?.id}
                onSelect={setSelectedTravel}
            />
        ));
    };


    return (
        <div className="flex">
            <div className="card zoomin animation-duration-500 mx-2 p-3 h-full">
                <TabView>
                    <TabPanel header="Voyages à venir">
                        <DataView 
                            value={filterTravelData()}
                            listTemplate={listTemplate}
                            loading={travelQLoading || travelQIsFetching}
                            loadingIcon={loader}
                            header={
                                <TravelHeader
                                    selectedRoute={selectedRoute}
                                    dateRange={upcomingTravelDateRange}
                                    routes={routesData}
                                    isLoading={routeQLoading || routeQIsFetching}
                                    onRouteChange={setSelectedRoute}
                                    onDateRangeChange={setUpcomingTravelDateRange}
                                />
                            }
                            paginator
                            rows={4}
                            pt= {{
                                header: {
                                    className: "bg-transparent pt-1",
                                    style: {
                                        borderTop: "none"
                                    }
                                }
                            }}
                        />
                    </TabPanel>
                    <TabPanel header="Voyages passés">
                        <DataView 
                            value={filterPastTravelData()}
                            listTemplate={listTemplate}
                            loading={pastTravelQLoading || pastTravelQIsFetching}
                            loadingIcon={loader}
                            header={
                                <TravelHeader
                                    selectedRoute={selectedRoute}
                                    dateRange={pastTravelDateRange}
                                    routes={routesData}
                                    isLoading={routeQLoading || routeQIsFetching}
                                    onRouteChange={setSelectedRoute}
                                    onDateRangeChange={setPastTravelDateRange}
                                />
                            }
                            paginator
                            rows={4}
                            pt= {{
                                header: {
                                    className: "bg-transparent pt-1",
                                    style: {
                                        borderTop: "none"
                                    }
                                }
                            }}
                        />
                    </TabPanel>
                </TabView>
            </div>
            <TravelPassengersList travelId={selectedTravel?.id} />
        </div>
    )

}